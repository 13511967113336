/* You can add global styles to this file, and also import other style files */


/*-----------------------------------------------------------------------------------*/
/*  1 - FONT IMPORT
/*-----------------------------------------------------------------------------------*/
@import url(http://fonts.googleapis.com/css?family=Open+Sans:400,300,700,800);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,700);
@import url(https://fonts.googleapis.com/css?family=Bree+Serif);
@import url(https://fonts.googleapis.com/css?family=Covered+By+Your+Grace);

@import "assets/css/style.css";

@import "assets/font-awesome-4.1.0/css/font-awesome.css";
@import "assets/css/pe-icons.css";
